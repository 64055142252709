// Required imports
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"


// Custom file imports
import { rhythm, scale } from "../utils/typography"
import Footer from '../components/footer.js'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const SubHeader = styled.h3`
  font-family: Montserrat, sans-serif;
`

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog`
    let header
    console.log(blogPath, location.pathname)
    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
        <h1
          className='sloth-font'
          style={{
            ...scale(1.45),
            marginBottom: rhythm(1.5),
            marginTop: 10,
            color: `white`
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={location.pathname === blogPath ? `/blog` : `/`}
          >
            {title.split(' ')[0]}<h1>{title.split(' ')[1]}</h1>
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          className='sloth-font'
          style={{
            color: 'white',
            marginTop: 10,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/blog`}
          >
            {title.split(' ')[0]}<SubHeader>{title.split(' ')[1]}</SubHeader>
          </Link>
        </h3>
      )
    }
    return (
      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
        </div>
        <Footer />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

export default Layout
